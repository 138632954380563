import '../../css/external/bootstrap-3.4.1/css/bootstrap.min.css'
import '../../css/components/login.css'

function hideAlert() {
    var alert = document.getElementsByClassName('alert-danger')

    if (alert && alert.length != 0) {
        alert[0].style.display = 'none'
    }
}

function changeLanguage(e) {
    var value = this.options[this.selectedIndex].value

    window.location.replace('login?lang=' + value)
}

document.addEventListener('DOMContentLoaded', function (event) {
    // Your code to run since DOM is loaded and ready
    var password = document.getElementById('password')
    var username = document.getElementById('username')

    password.addEventListener('focus', hideAlert)
    username.addEventListener('focus', hideAlert)

    document
        .getElementById('selectLang')
        .addEventListener('change', changeLanguage)
})
